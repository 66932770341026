<template>
  <div class="no-padding">
    <div style="height: 100%;">

      <b-form class="mt-8">

        <b-row>
          <b-col>
            <b-form-group id="input-group-file_id" label="ID" label-for="input-file_id">
              <b-form-input
                id="input-file_id"
                v-model="local_item.file_id"
                type="text"
                required
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" lg="4">
            <b-form-group id="input-group-file_name" :label="$t('PAGES.FILES.FILE_NAME')" label-for="input-file_name">
              <b-form-input
                id="input-file_name"
                v-model="local_item.name"
                type="text"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6" lg="4">
            <b-form-group id="input-group-display_name" :label="$t('PAGES.FILES.DISPLAY_NAME')" label-for="input-display_name">
              <b-form-input
                id="input-display_name"
                v-model="local_item.descr"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="mt-16">
          <h4 class="mb-8">{{$t('PAGES.FILES.ACCESSABILITY')}}</h4>

          <b-form-group id="group-public_link">
            <div class="d-flex align-items-center">
              <label class="checkbox checkbox-outline checkbox-success">
                <input id="input-public_link" type="checkbox" name="" v-model="local_item.is_public"/>
                <span></span>
              </label>
              <tip-label class="ml-3" for="input-public_link" :tip="$t('PAGES.FILES.PUBLIC_LINK_TIP')">{{$t('PAGES.FILES.PUBLIC_LINK')}}</tip-label>
            </div>
          </b-form-group>

          <b-form-group id="group-member_visible">
            <div class="d-flex align-items-center">
              <label class="checkbox checkbox-outline checkbox-success">
                <input id="input-member_visible" type="checkbox" name="" v-model="local_item.is_member" :disabled="local_item.member_id !== null"/>
                <span></span>
              </label>
              <tip-label class="ml-3" for="input-member_visible" :tip="$t('PAGES.FILES.MEMBER_VISIBLE_TIP')">{{$t('PAGES.FILES.MEMBER_VISIBLE')}}</tip-label>
            </div>
          </b-form-group>


          <b-form-group id="group-member_visible">
            <div class="d-flex align-items-center">
              <label class="checkbox checkbox-outline checkbox-success">
                <input id="input-member_visible" type="checkbox" name="" v-model="local_item.is_any_member"/>
                <span></span>
              </label>
              <tip-label class="ml-3" for="input-member_visible" :tip="$t('PAGES.FILES.MEMBER_ANY_VISIBLE_TIP')">{{$t('PAGES.FILES.MEMBER_ANY_VISIBLE')}}</tip-label>
            </div>
          </b-form-group>

          <b-form-group
            label-cols-md="6"
            label-cols-lg="4"
            label-cols-sm="10"
            content-cols-sm="10"
            content-cols-md="6"
            content-cols-lg="4"
            class="mt-8"
            id="member_type_id-group"
            :label="$t('FILE.VISIBLE_MEMBER_TYPE')"
            label-for="member_type_id">

            <b-form-select
              id="member_type_id"
              v-model="local_item.member_type_id"
              :options="member_type_options"/>
          </b-form-group>

          <b-form-group id="group-is_s3">
            <div class="d-flex align-items-center">
              <label class="checkbox checkbox-outline checkbox-success">
                <input id="input-is_s3" type="checkbox" :disabled="true" name="" v-model="local_item.is_s3"/>
                <span></span>
              </label>
              <tip-label class="ml-3" for="input-is_s3" :tip="$t('PAGES.FILES.IS_S3_TIP')">{{$t('PAGES.FILES.IS_S3')}}</tip-label>
            </div>
          </b-form-group>

        </div>

        <div class="mt-16">
          <h4 class="mb-8">{{$t('PAGES.FILES.FILE_INFO')}}</h4>
        </div>

        <div class="table-responsive">
          <table role="table" aria-busy="false" aria-colcount="5" class="table b-table">
            <tbody>
              <tr>
                <td class="pl-7">{{ $t('FILE.CREATED_BY') }}</td>
                <td v-if="local_item.user">
                  {{ local_item.user.firstname + ' ' + local_item.user.lastname + ' - ' + local_item.user.email }}
                </td>
              </tr>
              <tr>
                <td class="pl-7">{{ $t('FILE.CREATED_AT') }}</td>
                <td>{{ local_item.created_at }}</td>
              </tr>
              <tr>
                <td class="pl-7">{{ $t('FILE.SIZE_KB') }}</td>
                <td>{{ local_item.size_kb }} Kb</td>
              </tr>
              <tr>
                <td class="pl-7"></td>
                <td><a @click.prevent="downloadFileClicked" href="#" class="navi-link">{{ $t('FILE.DOWNLOAD') }}</a></td>
              </tr>
              <tr>
                <td class="pl-7"></td>
                <td><a @click.prevent="uploadToFortnoxClicked" href="#" class="navi-link">{{ $t('FILE.UPLOAD_TO_FORTNOX') }}</a></td>
              </tr>

            </tbody>
          </table>
        </div>

        <p style="margin-top: 42px;"></p>

        <b-row>
          <b-col>
            <RightModalSaveAndCloseButtons
              :text="$t('COMMON.SAVE')"
              :spin="true"
              @clicked="on_submit"
              @close="$emit('cancel')"
              ref="saveButton" />
          </b-col>
        </b-row>
      </b-form>


    </div>
  </div>
</template>


<script>


import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';

import axios from 'axios';
import dayjs from 'dayjs';

import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';
import TipLabel from '@/view/components/TipLabel.vue';

export default {

  name: 'FileEditor',

  props: ['item'],
  emits: ['updated', 'cancel'],
  mixins: [ toasts ],

  components: {
    RightModalSaveAndCloseButtons,
    TipLabel
  },

  watch: {
    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_item = { ...this.item };
      }
    }
  },


  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings'])
  },

  async mounted() {

    if (this.item) {
      this.local_item = { ...this.item }
    }

    await this.load_member_types();

  },

  methods: {


    async on_submit() {

      await this.update_file();

      this.$refs['saveButton'].stop();
    },

    async update_file() {

      try {

        const res = await axios.put(`/file/${this.local_item.file_id}`, this.local_item);

        if (res.status === 200) {
          this.$emit('updated', res.data);
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBERSHIP.UNABLE_UPDATE'));
      }
    },

    async load_member_types() {
      try {
        const res = await axios.get('/membertype');

        if (res.status === 200) {
          this.member_type_options = res.data.map(item => ({ value: item.id, text: item.name }));
        }

        this.member_type_options.unshift({ value: null, text: this.$t('FILE.MEMBER_TYPE_ANY') });

      }
      catch (err) {
        console.error('load_member_types error', err);

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.MEMBERTYPES.UNABLE_LIST_MEMBERTYPES'));
      }

    },

  },


  data() {
    return {

      local_item: {},

      member_type_options: []

    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
